"use client";

import Image from 'next/image';
import { Button } from "@/components/ui/button";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import Link from "next/link";
import Script from "next/script";
import React from "react";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import "animate.css";

const SectionHeroLayout3 = () => {
  return (
    <>
      {/* Load the Brevo script */}
      <Script
        src="https://meetings.brevo.com/assets/libs/popup.min.js"
        strategy="lazyOnload"
      />
      {/* Load the Brevo CSS */}
      <link
        href="https://meetings.brevo.com/assets/styles/popup.css"
        rel="stylesheet"
      />
      <div className="relative overflow-hidden bg-white dark:bg-transparent">
        <Image
          src="/hero/gradient_creative.webp"
          className="dark:hidden"
          fill
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          alt="Mubarokah Digital - Hero light"
          priority
          loading="eager"
          decoding="sync"
        />
        <Image
          src="/hero/gradient_creative_dark.webp"
          className="hidden dark:block"
          fill
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          alt="Mubarokah Digital - Hero Dark"
          priority
          loading="eager"
          decoding="sync"
        />
        <section className="pb-16 pt-28 md:pt-32 lg:pb-40 lg:pt-36">
          <div className="container relative z-10">
            <div className="flex flex-wrap lg:flex-nowrap lg:items-center lg:justify-between lg:space-x-10">
              <div className="mb-12 lg:mb-0 lg:w-2/5">
                <h1 className="text-headings text-3xl md:text-4xl lg:text-5xl xl:text-7xl">
                  Bringing Digital Magic{" "}
                  <span className="relative inline-block before:absolute before:bottom-3 before:-z-[1] before:h-2 before:w-full before:rounded-lg before:bg-primary before:bg-gradient-to-l before:from-primary before:to-tertiary before:opacity-70">
                    to Your Business.
                  </span>
                </h1>
                <p className="mb-10 text-xl">
                  Get extraordinary success with Mubarokah Digital & Creative Agency in our range of digital products, helping push your business growth to new heights.
                </p>
                <div className="flex flex-col md:flex-row gap-4">
                  <Link href="/pricing/">
                    <Button size="lg">
                      Get Started
                      <ChevronRightIcon
                        width={20}
                        height={20}
                        className="-mr-2 ml-4"
                      />
                    </Button>
                  </Link>
                  <Link href="#">
                    <Button
                      size="lg"
                      className="bg-white text-primary rounded-lg border-2 border-primary hover:bg-white shadow-md shadow-primary/40 hover:shadow-[0_2px_8px] hover:shadow-primary/40 hover:border-transparent animate__animated animate__heartBeat animate__delay-3s"
                      onClick={() => {
                        if (typeof window !== "undefined" && window.BrevoBookingPage) {
                          window.BrevoBookingPage.initStaticButton({
                            url: 'https://meet.brevo.com/mubarokah-digital-nusantara/borderless?l=get-a-quotation'
                          });
                        }

                        // Tracking the button click with LinkedIn
                        window.lintrk('track', { conversion_id: 17502220 });

                        return false;
                      }}
                    >
                      Book a Consultation
                      <ChevronRightIcon
                        width={20}
                        height={20}
                        className="-mr-2 ml-4"
                      />
                    </Button>
                  </Link>

                </div>

              </div>
              <div className="relative w-full text-center lg:w-1/2 lg:text-right">
                <div className="absolute bottom-[10%] left-[5%] animate-fly rounded-[4rem] bg-white p-4 pr-11 shadow-sm sm:-left-[8%]">
                  <div className="flex gap-4">
                    <span className="inline-flex h-12 w-12 items-center justify-center rounded-full bg-[#D3E9FF]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path d="M7 4V20H17V4H7ZM6 2H18C18.5523 2 19 2.44772 19 3V21C19 21.5523 18.5523 22 18 22H6C5.44772 22 5 21.5523 5 21V3C5 2.44772 5.44772 2 6 2ZM12 17C12.5523 17 13 17.4477 13 18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18C11 17.4477 11.4477 17 12 17Z"></path>
                      </svg>
                    </span>
                    <div className="text-left text-foreground">
                      <span className="block text-xs font-medium">
                        Mobile App Development
                      </span>
                      <span className="block text-2xl font-bold leading-none">
                        90%
                      </span>
                    </div>
                  </div>
                </div>
                <DotLottieReact
                  src="/lottie/Digital-Marketing-Analytics.json"
                  loop
                  autoplay
                  width={600}
                  height={600}
                />
                {/* <Image
                  src="/hero/hero-mubarokah.webp"
                  width={600}
                  height={600}
                  alt="hero digital agency"
                  className="inline-block"
                  loading="eager"
                  decoding="sync"
                  priority
                /> */}
                <div className="absolute right-[2%] top-1/3 animate-fly rounded-[4rem] bg-white p-4 pl-11 shadow-sm lg:-right-[5%]">
                  <div className="flex gap-4">
                    <div className="text-left text-foreground">
                      <span className="block text-xs font-medium">
                        Web Development
                      </span>
                      <span className="block text-2xl font-bold leading-none">
                        96%
                      </span>
                    </div>
                    <span className="inline-flex h-12 w-12 items-center justify-center rounded-full bg-[#E3F9EE]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path d="M12 18.1778L7.38083 16.9222L7.0517 13.3778H9.32156L9.48045 15.2222L12 15.8889L14.5195 15.2222L14.7806 12.3556H6.96091L6.32535 5.67778H17.6747L17.4477 7.88889H8.82219L9.02648 10.1444H17.2434L16.6192 16.9222L12 18.1778ZM3 2H21L19.377 20L12 22L4.62295 20L3 2ZM5.18844 4L6.48986 18.4339L12 19.9278L17.5101 18.4339L18.8116 4H5.18844Z"></path>
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="absolute -right-[5%] bottom-0 animate-fly rounded-xl bg-white p-8 shadow-sm sm:bottom-0 sm:right-[5%]">
                  <div className="text-left">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="absolute right-8 top-8 fill-[#EF4444]"
                    >
                      <path fill="none" d="M0 0H24V24H0z" />
                      <path d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228z" />
                    </svg>
                    <span className="block text-xs font-medium text-foreground">
                      Happy customers
                    </span>
                    <span className="mb-4 block text-2xl font-bold text-foreground">
                      +10k
                    </span>
                    <Image
                      src="/happy_customers.png"
                      alt="happy customers"
                      width={152}
                      height={24}
                      loading="lazy"
                      decoding="async"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default SectionHeroLayout3;
