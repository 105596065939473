"use client";

import TestimonialsSliderLayout2 from "@/components/testimonials-slider-layout-2";
import { Pagination } from "swiper/modules";
import SectionTitle from "./section-title";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

export const testimonials = [
  {
    name: "Rizky Pratama",
    company: "Marketing Manager",
    comment:
      "I am very satisfied with Mubarokah's services. Their digital marketing strategy has successfully increased our brand awareness and conversions. Thank you Mubarokah!",
    image: "/testimonials/testi-1.webp",

    rating: 5,
  },
  {
    name: "Lia Wijaya",
    company: "Entrepreneur",
    comment:
      "Mubarokah is the right digital marketing agency for your online business. They have a team of experts and experience in SEO, Google Ads, and social media marketing.",
    image: "/testimonials/testi-2.webp",
    rating: 5,
  },
  {
    name: "Adi Putra",
    company: "CEO Startup",
    comment:
      "Mubarokah creates designs that really understand the client's needs. They are able to create websites and mobile applications that are attractive and user-friendly. They are also responsive and flexible in providing solutions. I am very happy working with Mubarokah Agency.",
    image: "/testimonials/testi-1.webp",

    rating: 5,
  },
  {
    name: "Budi Santoso",
    company: "Owner",
    comment:
      "Mubarokah is a reliable digital partner for your business growth strategy. They provide complete and integrated digital solutions, from digital strategy, content marketing, to social media marketing.",
    image: "/testimonials/testi-1.webp",
    rating: 5,
  },
];

const SectionTestimonialsSliderLayout2 = () => {
  return (
    <section className="overflow-hidden bg-gradient-to-b from-[#EBE4FA]/25 to-muted/25 py-20 dark:bg-slate-900 dark:bg-none lg:py-24">
      <div className="container relative xl:left-[calc((100vw-1312px)/4)] xl:max-w-[calc(1312px+((100vw-1312px)/2))] xl:pr-[calc((100vw-1280px)/2)]">
        <SectionTitle
          subtitle="300+ Customers those who already tried our services."
          sectionClasses="mx-auto max-w-xl text-center mb-12"
          titleClasses="mb-3 text-center"
          subtitleClasses="text-md font-medium"
        >
          Satisfied Customers
        </SectionTitle>
        <TestimonialsSliderLayout2
          testimonials={testimonials}
          swiperParams={{
            breakpoints: {
              768: {
                slidesPerView: 2,
              },
              1025: {
                slidesPerView: 2,
              },
            },
            spaceBetween: 40,
            modules: [Pagination],
            pagination: { clickable: true },
          }}
        />
      </div>
    </section>
  );
};

export default SectionTestimonialsSliderLayout2;
