"use client";
import { motion } from "framer-motion";
import IconBoxLayout2 from "@/components/icon-box-layout-2";
import Link from "next/link";  // Import Link dari Next.js
import { Button } from "@/components/ui/button";
import Html5LineIcon from "remixicon-react/Html5LineIcon";
import SmartphoneLineIcon from "remixicon-react/SmartphoneLineIcon";
import PencilRuler2LineIcon from "remixicon-react/PencilRuler2LineIcon";
import AdvertisementLineIcon from "remixicon-react/AdvertisementLineIcon";
import ImageEditLineIcon from "remixicon-react/ImageEditLineIcon";
import ServerLineIcon from "remixicon-react/ServerLineIcon";

export const iconBoxes = [
  {
    icon: <Html5LineIcon className="fill-primary" size={32} />,
    iconBase: "bg-[#FEE8E8]",
    title: "Web Development",
    description:
      "A professional website creation service solution for SMEs and Corporations, or individuals.",
    shadow: "shadow-[0_1px_6px_rgba(61,65,84,.15),0_5px_0_0_#FA6262]",
  },
  {
    icon: <SmartphoneLineIcon className="fill-[#44D88D]" size={32} />,
    iconBase: "bg-[#E3F9EE]",
    title: "Mobile App Development",
    description: "Reach more users by bringing your business mobile app.",
    shadow: "shadow-[0_1px_6px_rgba(61,65,84,.15),0_5px_0_0_#44D88D]",
  },
  {
    icon: <PencilRuler2LineIcon className="fill-[#4C86E7]" size={32} />,
    iconBase: "bg-[#D3E9FF]",
    title: "UI/UX Designer, Graphics",
    description:
      "Create a visual experience of your brand to your customers with a stunning design.",
    shadow: "shadow-[0_1px_6px_rgba(61,65,84,.15),0_5px_0_0_#4C86E7]",
  },
  {
    icon: <AdvertisementLineIcon className="fill-[#7444FF]" size={32} />,
    iconBase: "bg-[#EAE3FF]",
    title: "Digital Marketing",
    description:
      "Deliver your products/services with digital advertising, reach customers on social media with our social media management services, to copywriting.",
    shadow: "shadow-[0_1px_6px_rgba(61,65,84,.15),0_5px_0_0_#7444FF]",
  },
  {
    icon: <ImageEditLineIcon className="fill-[#FFAF13]" size={32} />,
    iconBase: "bg-[#FFF3DC]",
    title: "Product Photos & Videos",
    description:
      "We provide product photography, video creation, and promotional content. From concept to execution, we deliver engaging visuals to help your brand shine across digital platforms.",
    shadow: "shadow-[0_1px_6px_rgba(61,65,84,.15),0_5px_0_0_#FFAF13]",
  },
  {
    icon: <ServerLineIcon className="fill-[#B939E5]" size={32} />,
    iconBase: "bg-[#FAF1FF]",
    title: "Hosting, Cloud, VPS",
    description:
      "Online your business with a reliable server. Get hosting or cloud vps for your web applications securely.",
    shadow: "shadow-[0_1px_6px_rgba(61,65,84,.15),0_5px_0_0_#B939E5]",
  },
];

const fadeInAnimationVariants = {
  initial: {
    opacity: 0,
    y: 60,
  },
  animate: (index: number) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.05 * index,
    },
  }),
};

const SectionIconBoxesLayout2 = () => {
  return (
    <section className="py-16 lg:py-24">
      <div className="container">
        <div className="flex justify-center">
          <div className="text-center lg:w-3/5">
            <h2 className="mb-12">
              Are you still confused about{" "}
              <span className="text-primary">how to grow</span> your business?
              We know the solution.
            </h2>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-3 lg:gap-10">
          {iconBoxes.map((iconBox, index) => {
            return (
              <motion.div
                key={iconBox.title}
                variants={fadeInAnimationVariants}
                initial="initial"
                whileInView="animate"
                viewport={{ once: true }}
                transition={{
                  delay: 0.5,
                }}
                custom={index}
              >
                <IconBoxLayout2 iconBox={iconBox} />
              </motion.div>
            );
          })}
        </div>
        {/* Tambahkan tombol di sini */}
        <div className="flex justify-center mt-12">
          <Link href="/services/">
            <Button size="lg">
              View All Services
            </Button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default SectionIconBoxesLayout2;
